import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container wrapper"},[_c('h1',{staticClass:"pageHeader my-12"},[_vm._v("Admin Account")]),_c('div',{staticClass:"d-flex mb-12"},[_c(VBtn,{staticClass:"tabBtn me-5",class:_vm.currentTab == 'activated' ? 'active ' : '',on:{"click":function($event){_vm.currentTab = 'activated'}}},[_vm._v(" Connected To ("+_vm._s(_vm.lists.activated.length)+") ")]),_c(VBtn,{staticClass:"tabBtn",class:_vm.currentTab == 'pending' ? 'active ' : '',on:{"click":function($event){_vm.currentTab = 'pending'}}},[_vm._v(" Pending ("+_vm._s(_vm.lists.pending.length)+") ")]),_c(VMenu,{attrs:{"dark":"","left":"","z-index":"0","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"ml-auto align-self-center"},[_c(VIcon,_vm._g({staticClass:"icon",attrs:{"color":"white"}},on),[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c(VList,[(_vm.currentTab == 'activated')?_c(VListItem,{staticClass:"bottomBorder",on:{"click":function($event){return _vm.$router.push('/create-admin')}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Create Admin")])],1):_vm._e(),_c(VListItem,{class:_vm.currentTab === 'pending' ? 'bottomBorder' : '',on:{"click":function($event){_vm.isSelect = true;
            _vm.processType = 'remove';}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Remove Admin")])],1),(_vm.currentTab === 'pending')?_c(VListItem,{on:{"click":function($event){_vm.isSelect = true;
            _vm.processType = 'resend';}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Resend Invitation Email")])],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"mb-2 d-flex"},[(!_vm.isSelect)?_c('div',[_vm._v("("+_vm._s(_vm.lists[_vm.currentTab].length)+")")]):_c('div',[_vm._v("("+_vm._s(_vm.requestId.length)+")")]),(_vm.isSelect)?_c('div',{staticClass:"ml-auto"},[_c('span',{staticClass:"clickable",on:{"click":function($event){_vm.isSelect = false}}},[_vm._v("Cancel")]),_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('span',{staticClass:"clickable",on:{"click":_vm.processAdmin}},[_vm._v(" "+_vm._s(_vm.process.title)+" ")])]):_vm._e()]),(_vm.lists[_vm.currentTab].length)?_c(VRow,{staticClass:"mb-12 pb-12",attrs:{"no-gutters":""}},_vm._l((_vm.lists[_vm.currentTab]),function(item,index){return _c(VCol,{key:index,staticClass:"userContainer d-flex",class:index != 0 ? 'mt-5' : '',attrs:{"cols":"12","md":"12"}},[(_vm.isSelect)?_c(VCheckbox,{staticClass:"pl-5 align-self-center",attrs:{"on-icon":"mdi-check-circle-outline","off-icon":"mdi-checkbox-blank-circle-outline","color":"primary","value":item.id,"number":"","dark":""},model:{value:(_vm.requestId),callback:function ($$v) {_vm.requestId=$$v},expression:"requestId"}}):_vm._e(),_c('div',{staticClass:"py-5 px-10 align-self-center"},[_c(VAvatar,{attrs:{"size":"60"}},[_c(VImg,{attrs:{"src":"/assets/image/icons/profile.png"}})],1)],1),_c(VRow,{staticClass:"my-5",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"py-2 align-self-center d-flex",attrs:{"cols":"12","md":"12"}},[_c('div',[_c('div',{staticClass:"titleFont"},[_vm._v(" "+_vm._s(item.designation)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.fname)+" ")])]),(_vm.currentTab !== 'pending')?_c('div',{staticClass:"ml-auto me-5 align-self-center"},[_c(VMenu,{attrs:{"dark":"","left":"","z-index":"0","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"icon",attrs:{"color":"white"}},on),[_vm._v(" mdi-chevron-right ")])]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.transferOwnership(item)}}},[_c(VListItemTitle,{staticClass:"px-5"},[_vm._v("Transfer Ownership")])],1)],1)],1)],1):_vm._e()])],1)],1)}),1):_c(VRow,{staticClass:"mb-12 pb-12",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"userContainer d-flex px-5 py-8",attrs:{"cols":"12","md":"12"}},[_c('div',[_vm._v("No admin")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }