<template>
  <div class="container wrapper">
    <h1 class="pageHeader my-12">Admin Account</h1>
    <div class="d-flex mb-12">
      <v-btn
        :class="currentTab == 'activated' ? 'active ' : ''"
        class="tabBtn me-5"
        @click="currentTab = 'activated'"
      >
        Connected To ({{ lists.activated.length }})
      </v-btn>
      <v-btn
        :class="currentTab == 'pending' ? 'active ' : ''"
        class="tabBtn"
        @click="currentTab = 'pending'"
      >
        Pending ({{ lists.pending.length }})
      </v-btn>
      <v-menu dark left z-index="0" offset-y>
        <template v-slot:activator="{ on }">
          <div class="ml-auto align-self-center">
            <v-icon color="white" class="icon" v-on="on">
              mdi-dots-vertical
            </v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item
            class="bottomBorder"
            v-if="currentTab == 'activated'"
            @click="$router.push('/create-admin')"
          >
            <v-list-item-title class="px-8">Create Admin</v-list-item-title>
          </v-list-item>
          <v-list-item
            :class="currentTab === 'pending' ? 'bottomBorder' : ''"
            @click="
              isSelect = true;
              processType = 'remove';
            "
          >
            <v-list-item-title class="px-8">Remove Admin</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="currentTab === 'pending'"
            @click="
              isSelect = true;
              processType = 'resend';
            "
          >
            <v-list-item-title class="px-8"
              >Resend Invitation Email</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="mb-2 d-flex">
      <div v-if="!isSelect">({{ lists[currentTab].length }})</div>
      <div v-else>({{ requestId.length }})</div>
      <div class="ml-auto" v-if="isSelect">
        <span class="clickable" @click="isSelect = false">Cancel</span>
        <span class="mx-5">|</span>
        <span class="clickable" @click="processAdmin">
          {{ process.title }}
        </span>
      </div>
    </div>
    <v-row no-gutters class="mb-12 pb-12" v-if="lists[currentTab].length">
      <v-col
        v-for="(item, index) in lists[currentTab]"
        :key="index"
        cols="12"
        md="12"
        class="userContainer d-flex"
        :class="index != 0 ? 'mt-5' : ''"
      >
        <v-checkbox
          v-if="isSelect"
          on-icon="mdi-check-circle-outline"
          off-icon="mdi-checkbox-blank-circle-outline"
          class="pl-5 align-self-center"
          color="primary"
          :value="item.id"
          v-model="requestId"
          number
          dark
        ></v-checkbox>
        <div class="py-5 px-10 align-self-center">
          <v-avatar size="60">
            <v-img src="/assets/image/icons/profile.png" />
          </v-avatar>
        </div>
        <v-row no-gutters class="my-5">
          <v-col cols="12" md="12" class="py-2 align-self-center d-flex">
            <div>
              <div class="titleFont">
                {{ item.designation }}
              </div>
              <div>
                {{ item.fname }}
              </div>
            </div>
            <div
              class="ml-auto me-5 align-self-center"
              v-if="currentTab !== 'pending'"
            >
              <v-menu dark left z-index="0" offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon class="icon" color="white" v-on="on">
                    mdi-chevron-right
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item @click="transferOwnership(item)">
                    <v-list-item-title class="px-5"
                      >Transfer Ownership</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb-12 pb-12" no-gutters v-else>
      <v-col cols="12" md="12" class="userContainer d-flex px-5 py-8">
        <div>No admin</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {
    PROFILE_INITIAL_GET_ADMIN_LIST_STATE,
    PROFILE_GET_ADMIN_LIST,
    PROFILE_INITIAL_RESEND_INVITATION_STATE,
    PROFILE_RESEND_INVITATION,
    PROFILE_INITIAL_REMOVE_ADMIN_STATE,
    PROFILE_REMOVE_ADMIN,
    PROFILE_INITIAL_TRANSFER_OWNERSHIP_STATE,
    PROFILE_TRANSFER_OWNERSHIP
  } from '@/store/profile.module';

  export default {
    name: 'AdminAccount',
    data: () => ({
      lists: {
        activated: [],
        pending: []
      },
      isSelect: false,
      requestId: [],
      currentTab: 'activated',
      processType: '',
      process: null
    }),
    mounted() {
      this.getAdminList();
      if (this.$route?.params?.createdAdmin) {
        this.$openModal('messageModal', 'Admin account successfully created');
      }
    },
    computed: {
      adminListComplete() {
        return this.$store.state.profile.adminList.complete;
      },
      resendInvitationComplete() {
        return this.$store.state.profile.resendInvitation.complete;
      },
      removeAdminComplete() {
        return this.$store.state.profile.removeAdmin.complete;
      },
      transferOwnershipComplete() {
        return this.$store.state.profile.transferOwnership.complete;
      }
    },
    watch: {
      adminListComplete() {
        let response = this.$store.state.profile.adminList;
        if (response.complete) {
          this.importAdminList(response);
        }
      },
      processType(val) {
        switch (val) {
          case 'remove':
            this.process = {
              type: 'remove',
              method: PROFILE_REMOVE_ADMIN,
              title: 'Remove'
            };
            break;
          case 'resend':
            this.process = {
              type: 'resend',
              method: PROFILE_RESEND_INVITATION,
              title: 'Resend'
            };
            break;
          default:
            this.process = null;
        }
      },
      resendInvitationComplete() {
        let response = this.$store.state.profile.resendInvitation;
        if (response.complete) {
          this.initialResendInvitationState();
          if (response.code === 200) {
            this.$openModal(
              'messageModal',
              'Invitation(s) had been sent successfully'
            );
          }
        }
      },
      removeAdminComplete() {
        let response = this.$store.state.profile.removeAdmin;
        if (response.complete) {
          this.initialRemoveAdminState();
          if (response.code === 200) {
            this.getAdminList();
            this.$openModal('messageModal', 'Successfully remove admin(s)');
          }
        }
      },
      transferOwnershipComplete() {
        let response = this.$store.state.profile.transferOwnership;
        if (response.complete) {
          this.initialTransferOwnershipState();
          if (response.code === 200) {
            this.getAdminList();
            this.$openModal(
              'messageModal',
              'Successfully transferred ownership'
            );
          }
        }
      }
    },
    methods: {
      processAdmin() {
        if(this.process.type == 'remove') this.openAppDialogInfo(
            '',
            `Are you sure you want to remove the admin from your agency?`,
            'Note: This action cannot be undone.',
            [
              {
                text: 'No',
                action: () => {
                  this.closeAppDialogInfo();
                }
              },
              {
                text: 'Yes',
                class: 'btn3',
                action: () => {
                  this.$store.dispatch(this.process.method, { data: this.requestId });
                  this.closeAppDialogInfo();
                }
              }
            ],
            false
          );
        else this.$store.dispatch(this.process.method, { data: this.requestId });
      },
      importAdminList(response) {
        this.lists = {
          activated: response.data?.activated,
          pending: response.data?.pending
        };

        if (this.lists) {
          this.adminListCompleteAction();
        }
      },
      transferOwnership(admin) {
        this.openAppDialogInfo(
          '',
          `Are you sure to transfer the ownership to ${admin.fname}?`,
          'Note: You need to re-login for the changes to take effect.',
          [
            {
              text: 'No',
              action: () => {
                this.closeAppDialogInfo();
              }
            },
            {
              text: 'Yes',
              class: 'btn3',
              action: () => {
                this.$store.dispatch(PROFILE_TRANSFER_OWNERSHIP, { id: admin.id });
                this.closeAppDialogInfo();
              }
            }
          ],
          false
        );
      },
      adminListCompleteAction() {
        this.initialAdminListState();
      },
      initialAdminListState() {
        this.$store.dispatch(PROFILE_INITIAL_GET_ADMIN_LIST_STATE);
      },
      getAdminList() {
        this.$store.dispatch(PROFILE_GET_ADMIN_LIST);
      },
      initialResendInvitationState() {
        this.$store.dispatch(PROFILE_INITIAL_RESEND_INVITATION_STATE);
      },
      initialRemoveAdminState() {
        this.$store.dispatch(PROFILE_INITIAL_REMOVE_ADMIN_STATE);
      },
      initialTransferOwnershipState() {
        this.$store.dispatch(PROFILE_INITIAL_TRANSFER_OWNERSHIP_STATE);
      }
    }
  };
</script>

<style scoped>
  .bottomBorder {
    border-bottom: 1px solid grey;
  }
  .userContainer {
    background-color: #242424;
    border-radius: 5px;
  }
  .titleFont {
    font-size: 15px;
  }

  .clickable {
    cursor: pointer;
  }
</style>
